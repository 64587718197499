exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-admission-js": () => import("./../../../src/pages/contact-admission.js" /* webpackChunkName: "component---src-pages-contact-admission-js" */),
  "component---src-pages-event-events-json-record-id-js": () => import("./../../../src/pages/event/{EventsJson.recordId}.js" /* webpackChunkName: "component---src-pages-event-events-json-record-id-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-papers-js": () => import("./../../../src/pages/papers.js" /* webpackChunkName: "component---src-pages-papers-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-person-people-json-field-data-slug-js": () => import("./../../../src/pages/person/{PeopleJson.fieldData__slug}.js" /* webpackChunkName: "component---src-pages-person-people-json-field-data-slug-js" */),
  "component---src-pages-press-awards-js": () => import("./../../../src/pages/press-awards.js" /* webpackChunkName: "component---src-pages-press-awards-js" */),
  "component---src-pages-project-projects-json-field-data-slug-js": () => import("./../../../src/pages/project/{ProjectsJson.fieldData__slug}.js" /* webpackChunkName: "component---src-pages-project-projects-json-field-data-slug-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

